import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_CHUCK_NORRIS_API_URL
})

api.interceptors.response.use(
  response => {
    return response?.data
  },
  error => {
    return Promise.reject(error)
  }
)

export { api }
