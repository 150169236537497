import React, { useCallback } from 'react'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Highlighted from 'components/Highlighted'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import ShareIcon from '@mui/icons-material/Share'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'

/**
 * This component just render a card with some joke informations
 * @param {String} id The id of the joke.
 * @param {String} title The title of the joke.
 * @param {String} value The joke.
 * @param {String} emoji A emoji to be displayed.
 * @param {String} category The category of the joke.
 * @param {String} toHighlight The text searched by user.
 * @returns A card with the joke.
 */
export function JokeCard({ id, updatedAt, value, emoji, category, toHighlight }) {
  /**
   * Open a new tab to whatsapp for share jokes.
   * @param {String} jokeId Joke string to insert on whatsapp message
   */
  const shareJoke = useCallback(jokeId => {
    window.open(
      `whatsapp://send?text=I found this joke at Chuck Norris site, click here to read: ${process.env.REACT_APP_APP_URL}joker/id/${jokeId}`,
      '_blank'
    )
  })

  return (
    <Card elevation={3}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: grey[500] }} aria-label='recipe'>
            {emoji}
          </Avatar>
        }
        data-testid='card-header'
        title={category}
        subheader={`Last update ${updatedAt} ago`}
      />
      <CardContent color='primary'>
        <Typography data-testid='joke' variant='body2' color='text.secondary'>
          <Highlighted text={value} highlight={toHighlight}>
            {value}
          </Highlighted>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton data-testid='share-button' aria-label='share' onClick={() => shareJoke(id)}>
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}

JokeCard.defaultProps = {
  toHighlight: ''
}

JokeCard.propTypes = {
  id: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  updatedAt: PropTypes.string,
  value: PropTypes.string.isRequired,
  category: PropTypes.string,
  toHighlight: PropTypes.string
}

export default JokeCard
