import { MuiButton, MuiTextField } from 'overrides'
import { createTheme } from '@mui/material/styles'

import palette from 'theme/palette'

export const theme = createTheme({
  palette,
  shadows: ['none', 'none', 'none', '0px 1px 6px 0px rgba(0, 0, 0, 0.25)', '0px 2px 8px 1px rgba(0, 0, 0, 0.25)'],

  components: {
    MuiButton,
    MuiTextField
  }
})

export default theme
