import colors from 'theme/colors'

export default {
  primary: {
    light: colors.orange[100],
    main: colors.orange[500],
    dark: colors.orange[700],
    contrastText: colors.root[0]
  },
  background: {
    paper: colors.root[0],
    default: colors.root[0]
  }
}
