import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'

/**
 * A container, this may be used on routes to align content.
 * @return A component with CSS elements to align and justify content.
 */
export function Container({ children, ...props }) {
  return (
    <Box display='flex' height='100vh' justifyContent='center' alignItems='center' {...props}>
      <Box width='80vw' maxWidth={500}>
        {children}
      </Box>
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container
