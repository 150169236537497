export const orange = {
  50: '#fcf2e3',
  100: '#fae4c5',
  300: '#f4cf9a',
  500: '#f3b967',
  700: '#e59b33',
  900: '#cc7700'
}

export const root = {
  0: '#FFFFFF'
}

export const smoke = {
  10: '#FCFDFD',
  25: '#F8FAFC',
  50: '#F5F8FA',
  100: '#EAEEF4',
  200: '#D8DDEB',
  300: '#BEC7D6',
  400: '#AEBAC9',
  500: '#9FABBB',
  600: '#8A94A3',
  700: '#757D8C',
  800: '#484F5F',
  900: '#2E3545'
}

export default {
  orange,
  root,
  smoke
}
