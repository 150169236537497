import { Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { getAllCategories, getByFreeTextSearch } from 'services/Jokes'
import { useNavigate, useParams } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Box from '@mui/material/Box'
import { JokeCard } from 'components'
import ScrollContainer from 'react-indiana-drag-scroll'
import SearchIcon from '@mui/icons-material/Search'
import Skeleton from '@mui/material/Skeleton'
import Toolbar from '@mui/material/Toolbar'
import jokerHelper from 'helpers/jokes-helper'
import { timeAgo } from 'helpers/formatters'
import { toast } from 'react-toastify'

/**
 * Route what render the search page.
 * @return A page with input and two buttons.
 */
export function JokesList() {
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [jokes, setJokes] = useState([])
  const [selectedCategories, setSelectectedCategories] = useState(Object.keys(jokerHelper))
  const navigate = useNavigate()
  const { search } = useParams()

  useEffect(() => {
    /**
     * Get all categories and filtered jokes from API when page render.
     */
    const fetchCategoriesAndSearch = async () => {
      try {
        const categoriesResponse = await getAllCategories()
        const searchResponse = await getByFreeTextSearch(search)

        setCategories(categoriesResponse)
        setJokes(searchResponse.result)
      } catch (ex) {
        toast.error('Ops, ocorreu um erro desconhecido. Tente novamente mais tarde.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchCategoriesAndSearch()
  }, [])

  /**
   * Append and remove a category from selected categories.
   * @param {String} category Name of the category.
   */
  const handleSelectedCategories = useCallback(category => {
    if (selectedCategories.includes(category)) {
      setSelectectedCategories(selectedCategories.filter(c => c !== category))
    } else {
      setSelectectedCategories([...selectedCategories, category])
    }
  })

  return (
    <Box display='flex' justifyContent='center' sx={{ display: 'flex' }}>
      <AppBar component='nav' color='inherit'>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            data-testid='back-button'
            size='large'
            aria-label='search'
            color='primary'
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <Typography variant='h6' color='primary' fontSize={30} fontWeight={900}>
            ChuckNorris
          </Typography>

          <IconButton
            data-testid='search-button'
            size='large'
            aria-label='search'
            color='primary'
            onClick={() => navigate('/')}
          >
            <SearchIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Toolbar>
          <ScrollContainer>
            <Stack display='flex' direction='row' width='100vw' alignItems='flex-end' spacing={1}>
              {isLoading
                ? [...Array(16).keys()].map(i => <Chip key={`chip-skeleton${i}`} label={<Skeleton width={35} />} />)
                : categories?.map(category => (
                    <Chip
                      variant={selectedCategories.includes(category) ? 'contained' : 'outlined'}
                      color='primary'
                      data-testid={`category-chip-${category}`}
                      key={category}
                      label={category}
                      onClick={() => handleSelectedCategories(category)}
                    />
                  ))}
            </Stack>
          </ScrollContainer>
        </Toolbar>
      </AppBar>
      <Box component='main' sx={{ p: 3 }}>
        <Toolbar />
        <Toolbar />
        <Stack width='80vw' maxWidth={500} spacing={2}>
          {isLoading
            ? [...Array(20).keys()].map(i => (
                <Skeleton key={`stack-skeleton${i}`} width='100%'>
                  <JokeCard id='' value='' />
                </Skeleton>
              ))
            : jokes
                ?.filter(joke => joke.categories.some(category => selectedCategories.includes(category)))
                ?.map(joke => (
                  <JokeCard
                    data-testid={`joke-card-${joke.id}`}
                    key={joke.id}
                    id={joke.id}
                    toHighlight={search}
                    emoji={jokerHelper[joke.categories[0]]?.emoji}
                    category={jokerHelper[joke.categories[0]]?.translation}
                    updatedAt={joke.updated_at && timeAgo(joke.updated_at)}
                    value={joke.value}
                  />
                ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default JokesList
