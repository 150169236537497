import { ROOT_FONT_SIZE } from 'theme/constants'

/**
 * Convert the given pixels to `rem` unit.
 *
 * @param {number} pixels
 * @returns {string} - Pixels converted to `rem`
 */
const pxToRem = pixels => {
  return `${pixels / ROOT_FONT_SIZE}rem`
}

export { pxToRem }
export default { pxToRem }
