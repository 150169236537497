import PropTypes from 'prop-types'
import React from 'react'

/**
 * This component just render a text with highlighted text.
 *
 * Reference: https://thewebdev.info/2021/11/13/how-to-highlight-text-using-react/
 * @param {string} text Some text
 * @param {string} highlight The text to be highlighted
 * @returns A string with the highlighted text
 */
export function Highlighted({ text, highlight }) {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }
  const regex = new RegExp(`(${highlight})`, 'gi')
  const parts = text.split(regex)

  return (
    <span>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
      })}
    </span>
  )
}

Highlighted.defaultValues = {
  text: '',
  highlight: ''
}

Highlighted.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string
}

export default Highlighted
