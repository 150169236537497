export default {
  animal: { translation: 'Animals', emoji: '🐼' },
  career: { translation: 'Carrer', emoji: '💼' },
  celebrity: { translation: 'Celebrity', emoji: '✨' },
  dev: { translation: 'Developer', emoji: '👨‍💻' },
  explicit: { translation: 'Explicit', emoji: '🔞' },
  fashion: { translation: 'Fashion', emoji: '👗' },
  food: { translation: 'Food', emoji: '🍽' },
  history: { translation: 'History', emoji: '🗺' },
  money: { translation: 'Money', emoji: '💰' },
  movie: { translation: 'Movie', emoji: '🎬' },
  music: { translation: 'Music', emoji: '🎵' },
  political: { translation: 'Political', emoji: '🗿' },
  religion: { translation: 'Religion', emoji: '📚' },
  science: { translation: 'Science', emoji: '🔬' },
  sport: { translation: 'Sport', emoji: '🏀' },
  travel: { translation: 'Travel', emoji: '🌍' },
  else: { translation: 'This joke does not have a category', emoji: '💡' }
}
