import Box from '@mui/material/Box'
import React from 'react'
import { Typography } from '@mui/material'

/**
 * Route what render the not found page
 * @return A page with texts.
 */
export function NotFound() {
  return (
    <Box width='100vw' height='100vh' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
      <Typography variant='h6'>You&apos;ve reached the edge of the universe</Typography>
      <Typography variant='body1'>I really thought space was constantly expanding.</Typography>
      <Typography fontSize={8}>Sorry, I couldn&apos;t be as funny as Chuck Norris</Typography>
    </Box>
  )
}

export default NotFound
