import { api } from 'providers/fetchChuckNorris'

/**
 * Do a request to the chuck norris api.
 * @param {String} text Some text
 * @returns Jokes what contains the text param.
 */
export const getByFreeTextSearch = text => api.get(`/jokes/search?query=${text}`)

/**
 * Do request to chucknorris api.
 * @param {String} category Some category
 * @returnsReturn a random joke from a specific category
 */
export const getRandomByCategory = category => api.get(`/jokes/random?category=${category}`)

/**
 * Do request to chucknorris api.
 * @returns {Promise} A promise with random joke
 */
export const getRandom = () => api.get('/jokes/random')
