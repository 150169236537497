import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom'

import { JokesList, NotFound, Search } from 'routes'

/**
 * This function contains some routes of application
 * @returns Public routes
 */
function UnauthorizedRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' element={<Search />} />
        <Route path='/jokes/:search' element={<JokesList />} />
        <Route path='*' element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  )
}

export default UnauthorizedRoutes
