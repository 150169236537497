import 'react-toastify/dist/ReactToastify.css'

import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import UnauthorizedRoutes from 'UnauthorizedRoutes'
import { createGlobalStyle } from 'styled-components'
import theme from 'theme'

const GlobalStyle = createGlobalStyle`
* {
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
button, a {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
`

/**
 * Default component that renders the first page.
 * @return The button with Hello World text.
 */
function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer />
      <UnauthorizedRoutes />
    </ThemeProvider>
  )
}

export default App
