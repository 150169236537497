import { TEXTFIELD_BORDER_RADIUS } from 'theme/constants'
import { pxToRem } from 'utils/styles'

export default {
  defaultProps: {
    variant: 'outlined',
    color: 'primary',
    size: 'small'
  },
  styleOverrides: {
    root: {
      fontWeight: 'bold',
      '& fieldset': {
        borderRadius: pxToRem(TEXTFIELD_BORDER_RADIUS)
      }
    }
  }
}
