import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  TextField
} from '@mui/material'
import { useCallback, useState } from 'react'
import ChuckNorris from 'assets/img/chucknorris.png'
import { Container } from 'components'
import { getRandom } from 'services/Jokes'
import { rollDice } from 'helpers/helper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

/**
 * Route what render the search page.
 * @return A page with input and two buttons.
 */
export function Search() {
  const [search, setSearch] = useState('')
  const [fellingLuckyOpen, setFellingLuckyOpen] = useState(false)
  const [isJokeLoading, setIsJokeLoading] = useState(false)
  const [error, setError] = useState({ hasError: false, message: '' })
  const [snackbarSettings, setSnackbarSettings] = useState({ open: false, message: '' })

  const [joke, setJoke] = useState({})

  const navigate = useNavigate()

  /**
   * Get a random joke from API.
   */
  const fetchJoke = async () => {
    try {
      const data = await getRandom()
      setJoke(data)
    } catch (ex) {
      toast.error('Ops, ocorreu um erro desconhecido. Tente novamente mais tarde.')
    } finally {
      setIsJokeLoading(false)
    }
  }

  /**
   * This just trigger one field to know if it's valid or not.
   *
   * There are other leaner ways to do this, an example is using the
   * React Hook Form, but I thought it best not to kill an ant with a bazooka.
   *
   * @returns {Boolean} True if the search is invalid.
   */
  const triggerFieldValidation = () => {
    const isFieldInvalid = search.length < 3
    setError({ hasError: isFieldInvalid, message: isFieldInvalid ? 'Please enter at least 3 characters' : '' })
    return isFieldInvalid
  }

  /**
   * Save what user type on the search field.
   */
  const handleSearch = useCallback(input => {
    setSearch(input.target.value)
  })

  /**
   * Just a logic of "lucky" for open modal with random joke.
   * @param {Boolean} open This mean if the modal need to be open or not.
   */
  const handleFellingLucky = useCallback(async () => {
    if (!fellingLuckyOpen && rollDice() === 6) {
      setIsJokeLoading(true)
      await fetchJoke()

      setFellingLuckyOpen(true)
    } else {
      !fellingLuckyOpen && setSnackbarSettings({ open: true, message: 'You are unlucky, try again!' })
      setFellingLuckyOpen(false)
    }
  })

  /**
   * Close the snackbar.
   */
  const handleCloseSnackbar = useCallback(() => {
    setSnackbarSettings({ open: false, message: '' })
  })

  /**
   * Navigate to jokes list route.
   */
  const handleNavigate = useCallback(() => {
    if (!triggerFieldValidation()) {
      navigate(`/jokes/${encodeURIComponent(search)}`)
    }
  })

  /**
   * Dectect Enter press
   */
  const handleKeyDown = useCallback(event => {
    if (event.key === 'Enter') {
      handleNavigate()
    }
  })

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} alignItems='center'>
          <Box
            data-testid='chucknorris-logo'
            m='auto'
            display='flex'
            justifyContent='center'
            alignItems='center'
            component='img'
            sx={{
              height: '100%',
              width: '100%',
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 }
            }}
            src={ChuckNorris}
          />
        </Grid>
        <Grid item xs={12} xl={12}>
          <TextField
            data-testid='search-textfield'
            fullWidth
            placeholder='Search a joke here'
            onChange={handleSearch}
            error={error.hasError}
            helperText={error.message}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            data-testid='search-button'
            fullWidth
            variant='outlined'
            color='primary'
            disabled={isJokeLoading}
            onClick={handleNavigate}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            data-testid='lucky-button'
            fullWidth
            variant='outlined'
            color='primary'
            disabled={isJokeLoading || snackbarSettings.open}
            onClick={handleFellingLucky}
          >
            I&apos;m felling lucky
          </Button>
        </Grid>
      </Grid>

      <Dialog
        data-testid='lucky-dialog'
        open={fellingLuckyOpen}
        onClose={handleFellingLucky}
        PaperProps={{ elevation: 0 }}
      >
        <DialogTitle>Here&apos;s a joke picked just for you</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{joke.value}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFellingLucky}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarSettings.open}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        key='bottomcenter'
      >
        <Alert severity='warning'>{snackbarSettings.message}</Alert>
      </Snackbar>
    </Container>
  )
}

export default Search
